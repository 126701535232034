import { render, staticRenderFns } from "./table.vue?vue&type=template&id=393d6109&lang=pug&"
import script from "./table.vue?vue&type=script&lang=coffee&"
export * from "./table.vue?vue&type=script&lang=coffee&"
import style0 from "./table.vue?vue&type=style&index=0&id=393d6109&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports