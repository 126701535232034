import { render, staticRenderFns } from "./provider_form.vue?vue&type=template&id=8b60a226&lang=pug&"
import script from "./provider_form.vue?vue&type=script&lang=coffee&"
export * from "./provider_form.vue?vue&type=script&lang=coffee&"
import style0 from "./provider_form.vue?vue&type=style&index=0&id=8b60a226&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports