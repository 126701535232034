import { render, staticRenderFns } from "./requests_panel.vue?vue&type=template&id=0b87b0e7&lang=pug&"
import script from "./requests_panel.vue?vue&type=script&lang=coffee&"
export * from "./requests_panel.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports